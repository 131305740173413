import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { ApiService } from '../core/api.service';
import { ApiResult } from '../core/models/apiresult';
import { Task } from '../core/models/task';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  onGoingTask$ = new BehaviorSubject<Task>(undefined);

  constructor(
    private authService: AuthService,
    private apiService: ApiService) { }

  loadOnGoingTask(): Observable<ApiResult> {
    return this.apiService.loadOnGoingTask(this.authService.user$.value.token)
      .pipe(
        tap((value: ApiResult) => {
          if(ApiResult.ok(value)) {
            const task = value.data as Task;

            this.onGoingTask$.next(task);
          }
        })
      )
  }

  startTask(id: number): Observable<ApiResult> {
    return this.apiService.startTask(id, this.authService.user$.value.token)
      .pipe(
        tap((value: ApiResult) => {
          if(ApiResult.ok(value)) {
            const task = value.data[0] as Task;

            this.onGoingTask$.next(task);
          }
        })
      )
  }

  endTask(id: number): Observable<ApiResult> {
    return this.apiService.endTask(id, this.authService.user$.value.token)
      .pipe(
        tap((value: ApiResult) => {
          if(ApiResult.ok(value)) {
            this.onGoingTask$.next(undefined);
          }
        })
      )
  }

  updateCurrentPosition(position: {lat: number, lng: number}): void{
    this.apiService.updateCurrentPosition(position, this.authService.user$.value.token)
      .subscribe(_ => console.log('Synched position @server'))
  }

}

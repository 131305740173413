import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { delay, delayWhen, filter, map, takeUntil } from 'rxjs/operators';
import { Task, TaskLocations } from 'src/app/core/models/task';
import { SystemService } from 'src/app/core/system.service';
import { TaskService } from 'src/app/tasks/task.service';
import { MapsAPILoader } from '@agm/core';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/core/api.service';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit, OnDestroy {

  task: Task;

  // center
  center: {lat: number, lng: number} = { lat: 38.7954, lng: -9.1852  }
  zoom: number = 12;
  top: number;
  
  // map 
  map: any;
  directionsService: any;
  directionsRenderer: any;
  icons: any = {};
  currentLocationMarker: any;

  @ViewChild('locationInfo') locationInfo: TemplateRef<any>;
  @ViewChild('infoWindow', {read: ViewContainerRef}) infoWindow: ViewContainerRef;

  ready: boolean;

  destroy$ = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private taskService: TaskService,
    private mapsAPILoader: MapsAPILoader,
    private systemService: SystemService) { }

  ngOnInit() {
    // mark app as loading state
    this.systemService.loading$.next(true);

    this.mapsAPILoader.load()
      .then(() => {        
        this.setupIcons();

        // set current location
        this.getCurrentLocation(true);

        // setup map
        this.initMap();

        // trees collection
        this.taskService.onGoingTask$
          .pipe(            
            takeUntil(this.destroy$),

            filter((value: Task) => !!value)
          )
          .subscribe((value: Task) => {
            this.task = value;

            // redraw map routes
            if(this.ready)
              this.calculateRoutes();
          });
      })
    
    // update current location marker
    setInterval(() => {
      console.log('updating current location,...');
            
      this.getCurrentLocation();

    }, 5000)
   
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initMap(){
    this.directionsService = new google.maps.DirectionsService();
    this.directionsRenderer = new google.maps.DirectionsRenderer({suppressMarkers: true});

    this.map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: this.zoom,
        center: this.center,
        disableDefaultUI: true,
        clickableIcons: false
      }
    );

    this.directionsRenderer.setMap(this.map);

    this.map.addListener("dragend", () => {      
      window.setTimeout(() => {
        this.infoWindow.remove();        
      }, 300);
    });


    // map ready
    google.maps.event.addListenerOnce(this.map, 'idle', function(){
      // do something only the first time the map is loaded
      this.ready = true;

      this.systemService.loading$.next(false);

    }.bind(this));

  }

  private getCurrentLocation(initializing?: boolean) {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }

        // update server current position
        this.taskService.updateCurrentPosition(this.center);

        // checkout 
        // https://developers.google.com/maps/documentation/javascript/geolocation

        // update map location
        if(initializing) { 
          this.map.setCenter(this.center); 
          this.calculateRoutes();
        }

        // update current location marker
        this.refreshCurrentLocationMarker();
        
        
      },
      (error) => {
        // for development only
        if(initializing)
          this.calculateRoutes();
        console.log(error);
      });
    }
  }

  private refreshMap() {
    // Add some markers to the map.
    // Note: The code uses the JavaScript Array.prototype.map() method to
    // create an array of markers based on a given "locations" array.
    // The map() method here has nothing to do with the Google Maps API.
    const markers = this.task.locations.map((task: TaskLocations, i) => {
      const marker = new google.maps.Marker({
        position: { lat: task.latitude, lng: task.longitude },
        //icon: this.getTreeIcon(tree),
      });

      // info window
      const infowindow = new google.maps.InfoWindow({
        content: '',
      });


      marker.addListener("click", () => {

      

      });

      // add marker to map 
      marker.setMap(this.map)

      return marker;

    });

    
  }

  private refreshCurrentLocationMarker() {
    // Add marker or just update position
    if(!!this.currentLocationMarker) {
      this.currentLocationMarker.setPosition(this.center);
    } else {
      this.currentLocationMarker = new google.maps.Marker({
        position: this.center,
        icon: this.icons.currentLocation.icon,
      });
    }
    
    this.currentLocationMarker.addListener("click", () => {
    });

    // add this.currentLocationMarker to map 
    this.currentLocationMarker.setMap(this.map);

    return this.currentLocationMarker;    
  }

  private calculateRoutes() {
    if(!this.task) { return; }

    this.systemService.loading$.next(true);

    const taskLocations = [...this.task.locations];

    const waypts: google.maps.DirectionsWaypoint[] = [];
    
    // setup routes
    const origin = this.center;

    let destination = undefined;    
    if(this.task.locations.length > 1) {
      // if theres more than 1 route, the last one should be the stopping route      
      const { latitude, longitude } =  taskLocations.pop();
      destination = { lat: +latitude, lng: +longitude };

      // setup waypoints
      taskLocations.forEach((value: TaskLocations) => {
        waypts.push({
          location: new google.maps.LatLng(+value.latitude, +value.longitude),
          stopover: true,
        });
      });
    } else {
      destination = { lat: +taskLocations[0].latitude, lng: +taskLocations[0].longitude };
    }

    
    this.directionsService
      .route({
        origin: origin,
        destination: destination,
        waypoints: waypts,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      })
      .then((response) => {
        this.systemService.loading$.next(false);

        this.directionsRenderer.setDirections(response);

        const route = response.routes[0];

        // setup custom icons
        for (let i = 0; i < route.legs.length; i++) {
          const leg = route.legs[i];

          console.log('leg: ', i, this.task.locations[i])

          // setup origin marker
          const marker = new google.maps.Marker({
            position: leg.start_location,
            //icon: this.icons.currentLocation.icon,
          });

          let locationRef: TaskLocations;
          if(i === 0) { 
            // start_location is current position
            locationRef = Object.assign(new TaskLocations(), { name: 'Local de partida' });
          } else {
            locationRef = this.task.locations[i - 1];            
          }
          this.setMarkerInfoWindow(marker, locationRef);  
          
          marker.setMap(this.map);

          // setup end location
          const markerEnd = new google.maps.Marker({
            position: leg.end_location,
            //icon: this.icons.currentLocation.icon,
          });

          // set up only the destination
          if(i === route.legs.length - 1) {
            locationRef = this.task.locations[i];
            this.setMarkerInfoWindow(markerEnd, locationRef);  
          }

          markerEnd.setMap(this.map);
        }

      })
      .catch((e) => window.alert("Directions request failed due to " + status));

  }

  setupIcons() { 
    this.icons = {
      currentLocation: {
        icon: {
          url: "assets/images/markers/my_location.svg",
          size: new google.maps.Size(54, 42),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(32, 22),
          scaledSize: new google.maps.Size(54, 42)
        }
      }
    };

  }

  private setMarkerInfoWindow(marker: google.maps.Marker, location: TaskLocations) {
    marker.addListener("click", () => {

      this.map.setCenter(marker.getPosition());
      this.infoWindow.remove();
      const locationInfo = this.locationInfo.createEmbeddedView({location: location})
      this.infoWindow.insert(locationInfo)
      
      setTimeout(() => {
        var scale = Math.pow(2, this.map.getZoom());
        var nw = new google.maps.LatLng(
            this.map.getBounds().getNorthEast().lat(),
            this.map.getBounds().getSouthWest().lng()
        );
        var worldCoordinateNW = this.map.getProjection().fromLatLngToPoint(nw);
        var worldCoordinate = this.map.getProjection().fromLatLngToPoint(marker.getPosition());
        var pixelOffset = new google.maps.Point(
            Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
            Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale)
        );

        const top = pixelOffset.x - 150;
        this.top = top + 100;

      });
    });
  }

  disbandInfoWindow(){  
    this.infoWindow.remove();
  }

}

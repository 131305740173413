<div class="dashboard-container">
  <!-- title -->
  <div class="main-header white">    
    <div class="font-lg">{{ 'DASHBOARD.TITLE' | translate }}</div>
    
    <!-- back button -->
    <a class="tasks-btn white" mat-button [routerLink]="'/tasks'">
      <span><i class="fas fa-bell"></i></span>
    </a>

  </div>

  <div class="main-container">
    <div>
      <app-google-map></app-google-map>
    </div>
  </div>   
</div>


<div class="ongoing-task" *ngIf="taskService.onGoingTask$ | async">
  <button mat-fab [routerLink]="['/tasks', taskService.onGoingTask$.value.id]" [queryParams]="{ returnUrl: 'dashboard' }">
    <span><i class="fas fa-tasks font-lg"></i></span>
  </button>
</div>
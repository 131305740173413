import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { SystemService } from './core/system.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  constructor(
    private router: Router,
    private systemService: SystemService,
    private translateService: TranslateService ) {}

  ngOnInit() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang('pt');

    // setup language to use
    this.translateService.use(this.systemService.language$.value);

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )    
      .subscribe((value: NavigationEnd) => {
        const handledRoutes = ['/login'];
        const activatedUrl = value.url;

        const loginAvailable = handledRoutes.includes(activatedUrl);
        // just manage body background color
        if(loginAvailable) {
          document.getElementsByTagName('body')[0].classList.add('light-green-background');
          document.getElementsByTagName('body')[0].classList.remove('green-background');
        } else {
          document.getElementsByTagName('body')[0].classList.remove('light-green-background');
          document.getElementsByTagName('body')[0].classList.add('green-background');
        }


        console.log(activatedUrl)
        
      })
  }

}

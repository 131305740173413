<div class="map">
  
  <div id="map"></div>

  <ng-container #infoWindow></ng-container>

</div>

<!-- loading -->
<div class="loading-image" *ngIf="!ready">
  <img src="assets/images/loading_map.png">
</div>


<!-- Info window -->
<ng-template #locationInfo let-location="location">
  <div class="location-info" [ngStyle]="{'top': top + 'px'}">    
    <div class="item-info">
      {{ !!location.name ? location.name : ('GOOGLE.MAP.INFO.UNAVAILABLE' | translate) }}
    </div>

    <div class="close-btn dark-gray" (click)="disbandInfoWindow()">
      <span><i class="icon-lg fas fa-times"></i></span>
    </div>
    
  </div>
</ng-template>


import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap } from 'rxjs/operators';
import { ApiResult } from 'src/app/core/models/apiresult';
import { SystemService } from 'src/app/core/system.service';
import { TaskService } from 'src/app/tasks/task.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    password: new FormControl('', Validators.required),
    matricula: new FormControl('', Validators.required),
  });

  showError: boolean;

  customPattern = {
    '9' : { pattern: new RegExp('\[0-9\]') },
    'a' : { pattern: new RegExp('\[a-zA-Z\]') },
    '*' : { pattern: new RegExp('\[a-zA-Z0-9\]') }
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private systemService: SystemService,
    private taskService: TaskService) { }

  ngOnInit() {
    if(this.systemService.getMobileOperatingSystem() === 'Android'){
      // focus events don't bubble, must use capture phase
      document.body.addEventListener("focus", event => {
        const target:any = event.target;
        switch (target.tagName) {
            case "INPUT":
            case "TEXTAREA":
            case "SELECT":
                document.body.classList.add("keyboard");
        }

        setTimeout(() => {
          //target.scrollIntoView();
        })

      }, true); 
      document.body.addEventListener("blur", () => {
        setTimeout(() => {
          const isFocused = document.activeElement.tagName === 'INPUT';
          if(!isFocused)
            document.body.classList.remove("keyboard");
        }, 300)
      }, true); 
    }
  }

  login() {
    const { email, password, matricula } = this.form.value;

    this.showError = false;

    this.authService.login(email, password, matricula)      
      .pipe(
        // load on going task for this user
        mergeMap(value => 
          this.taskService.loadOnGoingTask()
            .pipe(
              map(_ => value)
            )
          )
      )
      .subscribe((value: ApiResult) => {
        if(ApiResult.ok(value)){
          this.router.navigate([''])
        } else {
          this.showError = true;
        }
      })
  }
  

}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SystemService } from './system.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  constructor(
    private systemService: SystemService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // only handle backend calls
    if(request.url.includes('/api/updateCurrentPosition')) { return next.handle(request); }
    if(!request.url.includes('/api')) { return next.handle(request); }

    this.systemService.loading$.next(true);

    return next.handle(request)
      .pipe(        
        finalize(() => {
          this.systemService.loading$.next(false)
        })
      );
  }
}

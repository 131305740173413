import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateParser, TranslateDefaultParser } from '@ngx-translate/core';
import { SharedModule } from './shared/shared.module';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { AuthService } from './auth/auth.service';
import { HeaderInterceptor } from './core/header.interceptor';
import { SystemService } from './core/system.service';
import { AuthModule } from './auth/auth.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { TaskService } from './tasks/task.service';

export function createTranslateLoader(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { 
      prefix: 'assets/i18n/app-', 
      suffix: '.json' 
    }
  ]);
}

// start app system
export function start(systemService: SystemService, authService: AuthService, taskService: TaskService) {
  return () => systemService.start(authService, taskService);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,  
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      parser: {
        provide: TranslateParser,
        useClass: TranslateDefaultParser 
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCyLSpm5HWUhkaVePlssoSl42A7ilfMsN4'
    }),
    AuthModule,
    DashboardModule,
    SharedModule
  ],
  providers: [
    { 
      provide: APP_INITIALIZER, 
      useFactory: start, 
      deps: [SystemService, AuthService, TaskService], 
      multi: true 
    },
    { 
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true 
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiService } from '../core/api.service';
import { CacheService } from '../core/cache.service';
import { ApiResult } from '../core/models/apiresult';
import { User } from '../core/models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  user$ = new BehaviorSubject<User>(undefined);

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  constructor(
    private apiService: ApiService,
    private cacheService: CacheService) { }

  login(email: string, password: string, matricula: string): Observable<ApiResult> {
    return this.apiService.login(email, password, matricula)
      .pipe(
        tap((value: ApiResult) => {

          if(ApiResult.ok(value)) {
            const user = value.data as User;

            // set cache
            this.cacheService.setItem('USER', user);

            // update internal user
            this.user$.next(user);
          }       
        })
      );
  }

  loginByToken(token: string): Observable<ApiResult> {
    return this.apiService.loginByToken(token)
      .pipe(      
        tap((value: ApiResult) => {
        
          if(ApiResult.ok(value)) {
            const user = value.data as User;

            // set cache
            //this.cacheService.setItem('USER', user);

            // Login by token should return proper user 
            const cachedUser = this.cacheService.getItem('USER');

            // update internal user
            //this.user$.next(user);
            this.user$.next(cachedUser);
          }        
        })
      );
  }
  
}

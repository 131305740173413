<div class="login-container">
  <!-- logo -->
  <div class="logo">
    <img src="assets/images/login_logo.jpg">
  </div>

  <div class="title dark-green" [innerHtml]="('LOGIN.TITLE' | translate)"></div>

  <form [formGroup]="form" (submit)="login()">
    <div class="inner-container">
      <label class="custom-input">      
        <input type="text" placeholder="{{ 'LOGIN.EMAIL' | translate }}" formControlName="email">
      </label>

      <label class="custom-input">      
        <input type="password" placeholder="{{ 'LOGIN.PASSWORD' | translate }}" formControlName="password">
      </label>

      <label class="custom-input">      
        <input type="text" placeholder="{{ 'LOGIN.MATRICULA' | translate }}" formControlName="matricula" mask="AA-AA-AA" [dropSpecialCharacters]="false"><!--  [patterns]="customPattern" -->
      </label>

      <div class="errorMsg" [ngClass]="showError ? '' : 'hidden'">{{ 'LOGIN.ERROR.MSG' | translate }}</div>

      <button class="button main-button" type="submit" [disabled]="!form.valid">{{ 'LOGIN.LOGIN.BTN' | translate }}</button>
  
    </div>
  </form>
</div>

<!-- progress -->
<app-loading [topScreen]="true"></app-loading>
export class Task {
  id: number;
  date: string;
  title: string;
  content: string;
  thumbnail: string;

  status: TaskStatus = 0;

  locations: TaskLocations[] = [];
}

// tree locations
export class TaskLocations { 
  name: string; 
  latitude: number;
  longitude: number;
}

export enum TaskStatus {
  NONE = 0,
  STARTED = 1,
  COMPLETED = 2,
  PAUSED = 3
} 
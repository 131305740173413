import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, map, delay } from 'rxjs/operators';
import { CacheService } from './cache.service';
import { ApiResult } from './models/apiresult';
import { Task, TaskStatus } from './models/task';
import { User } from './models/user';
import { SystemService } from './system.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  readonly API_SERVICE = 'https://readyapp.makeitdigital2.tk/api';

  constructor(
    private systemService: SystemService,
    private cacheService: CacheService,
    private http: HttpClient) { }

  login(email: string, password: string, matricula: string): Observable<ApiResult> {
    const body = {
      username: email,
      password: password,
      plate: matricula
    }

    // mais@com.pt   12345

    const url = this.API_SERVICE + '/login';

    return this.http.post(url, body)
      .pipe(        
        tap(value => console.log(value)),

        map(value => value as ApiResult)
      )


    this.systemService.loading$.next(true);

    const user = Object.assign(new User(), {
      userId: 1,
      name: 'Miguel',
      email: email,
      password: password,
      token: 'DASAEW2131SDAS122'
    })
    const result = Object.assign(new ApiResult(), {code: 0, data: user});
    
    return of(result)
      .pipe(
        delay(2000),
        tap(_ => this.systemService.loading$.next(false))
      );
  }

  loginByToken(token: string): Observable<ApiResult> {    
    const body = {      
      token: token
    }

    const url = this.API_SERVICE + '/loginByToken';

    return this.http.post(url, body)
      .pipe(        
        tap(value => console.log(value)),

        map(value => value as ApiResult)
      )

    this.systemService.loading$.next(true);
    // logged user (DEV)
    const user: User = this.cacheService.getItem('USER');
    
    const result = Object.assign(new ApiResult(), {code: 0, data: user});

    return of(result)
      .pipe(
        delay(2000),
        tap(_ => this.systemService.loading$.next(false))
      );

    
  }

  // tasks
  loadTasks(token: string): Observable<ApiResult> {    
    const body = {      
      token: token
    }

    const url = this.API_SERVICE + '/loadTasks';

    return this.http.post(url, body)
      .pipe(        
        tap(value => console.log('loadTasks', value)),

        map(value => value as ApiResult)
      )

    this.systemService.loading$.next(true);

    const result = Object.assign(new ApiResult(), {
      data: this.tasks,
      code: 0
    })

    return of(result)
      .pipe(
        //delay(2000),
        tap(_ => this.systemService.loading$.next(false))
      );

  }

  loadTask(id, token): Observable<ApiResult> {
    const body = {
      id: id,
      token: token
    }

    const url = this.API_SERVICE + '/loadTask';

    return this.http.post(url, body)
      .pipe(        
        tap(value => console.log('loadTask', value)),

        map(value => value as ApiResult)
      )

    this.tasks.forEach(news => news.content = `
      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
      sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua. Ut enim ad minim veniam, quis nostrud
      exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
      in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
      sunt in culpa qui officia deserunt mollit anim id est
      laborum.

      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam
      rem aperiam, eaque ipsa quae ab illo inventore veritatis
      et quasi architecto beatae vitae dicta sunt explicabo.
      Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni
      dolores eos qui ratione voluptatem sequi nesciunt.
      Neque porro quisquam est, qui dolorem ipsum quia
      dolor sit amet, consectetur, adipisci velit, sed quia non
      numquam eius modi tempora incidunt ut labore et
      dolore magnam aliquam quaerat voluptatem. Ut enim
      ad minima veniam, quis nostrum exercitationem ullam
      corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae
      consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?`)

    const cachedOnGoingTask: Task = this.cacheService.getItem('ONGOING_TASK');
    
    const task = this.tasks.find((value: Task) => value.id === id);
    if(!!cachedOnGoingTask && task.id === cachedOnGoingTask.id) {
      task.status = cachedOnGoingTask.status;
    }
      
    const result = Object.assign(new ApiResult(), {
      data: [this.tasks.find((value: Task) => value.id === id)],
      code: 0
    })

    return of(result);
  }

  startTask(id, token): Observable<ApiResult> {
    const body = {
      id: id,
      token: token
    }

    const url = this.API_SERVICE + '/startTask';

    return this.http.post(url, body)
      .pipe(        
        tap(value => console.log('startTask', value)),

        map(value => value as ApiResult)
      )

    const task = this.tasks.find((value: Task) => value.id === id)
    task.status = TaskStatus.STARTED;

    const result = Object.assign(new ApiResult(), {
      data: [task],
      code: 0
    })

    return of(result) 
    .pipe(
      tap(_ =>   this.cacheService.setItem('ONGOING_TASK', task) )
    );
  }

  endTask(id, token): Observable<ApiResult> {
    const body = {
      id: id,
      token: token
    }

    const url = this.API_SERVICE + '/endTask';

    return this.http.post(url, body)
      .pipe(        
        tap(value => console.log('endTask', value)),

        map(value => value as ApiResult)
      )

    const task = this.tasks.find((value: Task) => value.id === id)
    task.status = TaskStatus.COMPLETED;

    const result = Object.assign(new ApiResult(), {
      data: [task],
      code: 0
    })

    return of(result)
      .pipe(
        tap(_ => this.cacheService.setItem('ONGOING_TASK', undefined))
      );
  }

  loadOnGoingTask(token): Observable<ApiResult> {
    const body = {
      token: token
    }

    const url = this.API_SERVICE + '/loadOnGoingTask';

    return this.http.post(url, body)
      .pipe(        
        tap(value => console.log('loadOnGoingTask', value)),

        map(value => value as ApiResult)
      )

    const task = this.cacheService.getItem('ONGOING_TASK');

    const result = Object.assign(new ApiResult(), {
      data: !!task ? [task] : [],
      code: 0
    });

    return of(result)
  }

  updateCurrentPosition(position: {lat: number, lng: number}, token): Observable<ApiResult> {
    return of({code: 0, data: null})
    const body = {
      postion: '',
      token: token
    }

    const url = this.API_SERVICE + '/updateCurrentPosition';

    return this.http.post(url, body)
      .pipe(                
        map(value => value as ApiResult)
      )
  }

  // DEV
  tasks = [
    Object.assign(new Task(), {title: 'Task 1', id: 1, status: TaskStatus.NONE, date: '20-10-2022', thumbnail: 'https://st2.depositphotos.com/5000011/8745/v/600/depositphotos_87456656-stock-illustration-vector-perspective-city-map-infographic.jpg', content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged",
      locations: [
        {latitude: 38.791115147105, longitude: -9.18672617091349, description: 'Ponto A'},
        {latitude: 38.8169192429239, longitude: -9.23489995766625, description: 'Ponto B'}
      ]
    }),
    Object.assign(new Task(), {title: 'Task 2', id: 5920, status: TaskStatus.NONE, date: '20-10-2022', thumbnail: 'https://st2.depositphotos.com/5000011/8745/v/600/depositphotos_87456656-stock-illustration-vector-perspective-city-map-infographic.jpg', content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged",
      locations: [
        {latitude: 38.8169192429239, longitude: -9.23489995766625, description: 'Ponto 1'},
        {latitude: 38.8151329410649, longitude: -9.23328357511469, description: 'Ponto 2'}
      ]
    }),
    Object.assign(new Task(), {title: 'Task 3', id: 5921, status: TaskStatus.NONE, date: '20-10-2022', thumbnail: 'https://st2.depositphotos.com/5000011/8745/v/600/depositphotos_87456656-stock-illustration-vector-perspective-city-map-infographic.jpg', content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged",
      locations: [
        {latitude: 38.8168922238248, longitude: -9.2348995413169}
      ]
    }),
    Object.assign(new Task(), {title: 'Task 4', id: 5924, status: TaskStatus.NONE, date: '20-10-2022', thumbnail: 'https://st2.depositphotos.com/5000011/8745/v/600/depositphotos_87456656-stock-illustration-vector-perspective-city-map-infographic.jpg', content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged",
      locations: [
        {latitude: 38.8151329410649, longitude: -9.23328357511469}
      ]
    }),
    Object.assign(new Task(), {title: 'Task 5', id: 5926, status: TaskStatus.NONE, date: '20-10-2022', thumbnail: 'https://st2.depositphotos.com/5000011/8745/v/600/depositphotos_87456656-stock-illustration-vector-perspective-city-map-infographic.jpg', content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged",
      locations: [
        {latitude: 38.8151788401984, longitude: -9.2331921735477}
      ]
    }),
    Object.assign(new Task(), {title: 'Task 6', id: 5925, status: TaskStatus.NONE, date: '20-10-2022', thumbnail: 'https://st2.depositphotos.com/5000011/8745/v/600/depositphotos_87456656-stock-illustration-vector-perspective-city-map-infographic.jpg', content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged",
      locations: [
        {latitude: 38.8151514958607, longitude: -9.23322629326501}
      ]
    }),
    Object.assign(new Task(), {title: 'Task 7', id: 5843, status: TaskStatus.NONE, date: '20-10-2022', thumbnail: 'https://st2.depositphotos.com/5000011/8745/v/600/depositphotos_87456656-stock-illustration-vector-perspective-city-map-infographic.jpg', content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged",
      locations: [
        {latitude: 38.8130252323434, longitude: -9.23327417415611}
      ]
    })
  ]

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { AgmMarkerClustererModule } from '@agm/markerclusterer';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from './material.module';
import { LoadingComponent } from './loading/loading.component';
import { MenuComponent } from '../shared/menu/menu.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { NgxMaskModule } from 'ngx-mask';



@NgModule({
  declarations: [LoadingComponent, MenuComponent, GoogleMapComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AgmCoreModule,
    AgmMarkerClustererModule,
    RouterModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    MaterialModule,    
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    RouterModule,
    AgmMarkerClustererModule,
    NgxMaskModule,
    LoadingComponent,
    MenuComponent,
    GoogleMapComponent
  ]
})
export class SharedModule { }

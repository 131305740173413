import { Injectable } from '@angular/core';
import { BehaviorSubject, concat } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CacheService } from './cache.service';
import { User } from './models/user';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  // language
  language$ = new BehaviorSubject<string>('pt');  

  // loading
  loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private cacheService: CacheService) { }

  start(authServce: any, taskService: any): Promise<boolean>  {
    return new Promise((resolve, reject) => {

      // DEV
      //authServce.isLoggedIn = true;

      // logged user
      const user: User = this.cacheService.getItem('USER');
      

      // get all needed data before ready to use
      let startupData = [        
      ]

      // auto login
      if(!!user) {
        startupData.push(
          authServce.loginByToken(user.token)
            .pipe(
              // load on going task for this user
              mergeMap(_ => taskService.loadOnGoingTask())
            )

          
        )
        
      }

      concat(...startupData)
        .subscribe({
          next: () => {},
          complete: () => resolve(true)
        });

    });

  }

  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor ;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) {
        return "iOS";
    }

    return "unknown";
  }
}
